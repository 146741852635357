@import "constants";
@import-js "constants.js";

.ant-menu {
  .ant-menu-item {
    background-size: 100px 3px, calc(100% - 3px) 100% !important;
    background-position: center bottom, 3px 0 !important;
    &.ant-menu-item-selected,
    &.ant-menu-item-active {
      background-image: linear-gradient(@black, @black) !important;
    }
    &:active {
      background-image: linear-gradient(@dark-grey2, @dark-grey2) !important;
    }

    &::after {
      border-bottom: none !important;
    }
  }
}

.LightPage {
  .category {
    .header {
      p {
        color: @dark-grey !important;
      }
      svg > * {
        fill: @dark-grey !important;
      }
      border-bottom: 1px solid @light-grey !important;
    }
  }
}
