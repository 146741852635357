@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/constants";
@import "../../less/motomuto_ras/fonts";

.MMButton {
  height: 57px;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: @green;
  color: @white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &:focus {
    outline: 0;
  }
  & > div.text {
    margin: auto;
    & > p {
      margin: 0;
      display: inline-block;
      .fontface-semibold();
      font-size: 20px;
    }
  }

  &.block {
    width: 100%;
  }

  &:disabled {
    background-color: fade(@green, 40%);
    cursor: not-allowed;
  }

  &.orange {
    background-color: @orange;

    .text p {
      color: white !important;
    }

    &:disabled {
      background-color: fade(@orange, 40%);
    }
  }

  &.red {
    background-color: @red;

    &:disabled {
      background-color: fade(@red, 40%);
    }
  }

  &.grey {
    background-color: @light-grey;

    &:disabled {
      background-color: fade(@light-grey, 40%);
    }
  }

  &.link {
    background: transparent;
    border: none;
    height: unset;

    & > div.text {
      // padding: 0;

      & > p {
        text-decoration: underline;
        font-size: 16px;
      }
    }
  }
}

.motomuto {
  .MMButton {
    border-radius: 0;
  }
}
