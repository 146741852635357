@import-js "APP_VARIANT/constants";

.vertical-transition(@enter-type,@start-positon,@end-position) {
  &-@{enter-type} {
    top: @start-positon;

    &-active {
      transition: top @modal-transition-duration ease-in;
      top: @end-position;
    }

    &-done {
      top: @end-position;
    }
  }

  &-exit {
    top: @end-position;

    &-active {
      transition: top @modal-transition-duration ease-out;
      top: @start-positon;
    }

    &-done {
      top: @start-positon;
    }
  }
}
