@import "~typeface-titillium-web/index.css";

.fontface {
  font-family: 'Titillium Web';
  font-style: normal;
}

.fontface-extra-light {
  .fontface();
  font-weight: 200;
}

.fontface-extra-light-italic {
  .fontface-extra-light();
  font-style: italic;
}

.fontface-light {
  .fontface();
  font-weight: 300;
}

.fontface-light-italic {
  .fontface-light();
  font-style: italic;
}

.fontface-regular {
  .fontface();
  font-weight: 400;
}

.fontface-regular-italic {
  .fontface-regular();
  font-style: italic;
}

.fontface-semibold {
  .fontface();
  font-weight: 600;
}

.fontface-semibold-italic {
  .fontface-semibold();
  font-style: italic;
}

.fontface-bold {
  .fontface();
  font-weight: 700;
}

.fontface-bold-italic {
  .fontface-bold();
  font-style: italic;
}

.fontface-black {
  .fontface();
  font-weight: 900;
}
