@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../../less/motomuto_ras/fonts";
@import "../../../less/motomuto_ras/constants";

.PaletteEditor {
  .container {
    position: relative;
    height: 100%;
  }

  .header {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    color: @dark-grey;
    .fontface-bold();
    font-size: 20px;
    margin: 0 0 10px;

    p {
      margin: 0;
    }

    .actions {
      display: inline-flex;
      justify-content: space-between;
      width: 45px;
      margin-top: 7px;
    }
  }

  .delete-color {
    margin-top: 15px;
  }

  .channels {
    display: inline-flex;
    justify-content: space-around;
    width: 100%;
    text-align: center;
    .fontface-light();
    color: @dark-grey;

    .channel {
      .key {
        font-size: 16px;
        margin: 0;
      }
      .value {
        margin-top: 10px;
        input {
          text-align: center;
        }
      }
    }
  }

  .body {
    .fontface-semibold();
    font-size: 16px;
    color: @light-grey;
    margin-bottom: 10px;

    p {
      margin-top: 2px;
      margin-bottom: 5px;
      font-size: 14px;
    }

    .MMTextInput {
      margin-bottom: 0;

      input {
        font-size: 16px;
      }
    }

    .MMInlineSelect {
      font-size: 12px;
    }
  }

  .footer {
    bottom: 5px;
    display: inline-flex;
    justify-content: space-evenly;
    width: 100%;

    .MMButton {
      p {
        font-size: 17px !important;
        color: @black;
      }
      &.delete p {
        color: @red;
      }
      &.save p {
        color: @green;
      }
    }
  }
}
