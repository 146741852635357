@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";
@import "../../less/motomuto_ras/constants";


.PalettesPage {
  margin-top: 30px;

  .palettes {
    margin: 0 auto;
    width: 1400px;
  }

  .palette {
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-left: 45px;
    margin-right: 45px;
    text-align: center;
    .fontface-light();
    font-size: 16px;
    list-style-type: none;
    box-sizing: border-box;

    .color {
      width: 85px;
      height: 85px;
      border-radius: 50%;
      background-color: black;

      &.white {
        width: 83px;
        height: 83px;
        border: 1px solid @dark-grey;
      }
    }

    &.add {
      .color {
        background-color: white;
        width: 83px;
        height: 83px;
        border: 1px solid @light-grey;
      }
    }

    .anticon {
      position: absolute;
      top: 85px / 2;
      left: 85px / 2;
      transform: translate(-50%, -50%);
      font-size: 30px;
      color: @light-grey;
    }
  }

  @media screen and (max-width: 400px) {
    .palettes {
      width: 175px;
    }
  }
  @media screen and (min-width: 401px) and (max-width: 600px) {
    .palettes {
      width: 350px;
    }
  }
  @media screen and (min-width: 601px) and (max-width: 800px) {
    .palettes {
      width: 525px;
    }
  }
  @media screen and (min-width: 801px) and (max-width: 1100px) {
    .palettes {
      width: 700px;
    }
  }
  @media screen and (min-width: 1101px) and (max-width: 1500px) {
    .palettes {
      width: 1050px;
    }
  }
}
