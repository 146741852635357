@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";
@import "../../less/motomuto_ras/constants";

.FixturesPage {
  height: 100%;
  margin: auto;
  display: block;
  max-height: calc(100vh - 170px);
  //width: 1400px;
  max-width: 100vw;

  .rename-dialog {
    .MMTextInput {
      margin-bottom: 0;
    }
  }

  .view-container {
    max-width: calc(100vw - 2px);
    width: calc(100% - 2px);
    max-height: inherit;
    overflow: auto;
    border: 1px solid black;
    position: relative;
    z-index: 0;

    .scaler {
      transform-origin: top left;
    }

    .MMCanvas:not(:nth-child(2)) {
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .MMCanvas:first-child {
      z-index: -1;
    }
  }

  .control-footer {
    display: inline-flex;
    position: fixed;
    z-index: 1;
    bottom: 5px;
    left: 0;
    padding: 9px 20px;
    line-height: 40px;
    text-align: center;

    p {
      margin: 0 20px 0 5px;
      .fontface-light();
    }

    .MMButton {
      width: 100px;
      height: 40px;

      p {
        .fontface-light();
        font-size: 16px;
      }
    }

    .MMSlider {
      margin-top: 12px;
      margin-right: 10px;
    }
  }

  .fixture {
    text-align: center;
    line-height: 47px;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    background-color: black;
    position: absolute;
  }

  .MMDialogModal {
    .MMButton {
      min-width: 100px;
    }
  }
}
