@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";

.MMSlider {
  position: relative;
  width: 100%;
  height: @fader-height;
  outline: none !important;
  & > * {
    outline: none !important;
  }

  .fill-selected {
    background-color: @yellow;
    height: @fader-height;
    border-radius: 9px;
    position: absolute;
    left: @fader-thumb-border;
    right: @fader-thumb-border;
    top: 0;
    bottom: 0;
    z-index: 1;

    .fader-arrows {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: fit-content;
      height: inherit;

      .fader-arrow {
        display: inline-block;
        background-color: @yellow2;
        width: 6px;
        height: 10px;
        margin: 4px 5px;
        clip-path: polygon(0 50%, 100% 100%, 100% 0);
        -webkit-clip-path: polygon(0 50%, 100% 100%, 100% 0);

        &:last-of-type {
          transform: rotate(180deg);
        }
      }
    }
  }

  .fill-background {
    background-color: @light-grey2;
    height: @fader-height;
    border-radius: 9px;
    position: absolute;
    left: @fader-thumb-border;
    right: @fader-thumb-border;
    top: 0;
    bottom: 0;
    z-index: 0;
  }

  .traveller {
    position: absolute;
    top: -@fader-thumb-border;
    margin: (@fader-height - @fader-thumb-size)/2;
    cursor: grab;
    z-index: 2;
    height: @fader-thumb-size;
    width: @fader-thumb-size;
    background-color: @dark-grey;
    border: @dark-grey2 solid @fader-thumb-border;
    border-radius: 50%;

    .tooltip {
      position: absolute;
      top: -27px;
      left: -@fader-thumb-size - @fader-thumb-border;
      color: @dark-grey;
      font-size: 12px;
      .fontface-regular();
    }
  }
}
