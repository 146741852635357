@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";

.MMRadio {
  @radio-size: 20px;
  .fontface-light();
  font-size: 14px;
  color: @dark-grey;
  display: flex;
  justify-content: space-around;

  .ant-radio-wrapper {
    margin-top: 6px;
    margin-right: 0px;
    display: inline-block;

    span {
      margin: 0;
      cursor: pointer;
      line-height: @radio-size;

      &:nth-child(2) {
        vertical-align: top;
        margin-left: 6px;
      }
    }

    .ant-radio {
      display: inline-block;
      position: relative;

      .ant-radio-inner {
        height: @radio-size;
        width: @radio-size;
        background-color: @light-grey2;
        border-radius: 12px;
        display: block;
        margin: 0;
      }

      &.ant-radio-checked {
        .ant-radio-inner {
          border: @green solid 8px;
          background-color: @dark-green;
          height: @radio-size - 8px*2;
          width: @radio-size - 8px*2;
        }
      }

      input {
        position: absolute;
        left: 0;
        z-index: 1;
        cursor: pointer;
        opacity: 0;
        top: 0;
        bottom: 0;
        right: 0;
        height: @radio-size;
        width: @radio-size;
        margin: 0;
      }
    }
  }
}
