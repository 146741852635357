@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";

.MMDatePicker {
  @input-height: 38px;
  @input-width: 115px;

  height: @input-height;
  width: @input-width;
  position: relative;

  .fontface-light();
  text-align: center;
  font-size: 24px;
  color: @dark-grey;

  border-bottom: 4px solid @light-grey2;

  .input-group {
    height: 100%;
    width: 100%;
  }

  input {
    background: transparent;
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 1;
    opacity: 0;
    &:focus {
      left: -5000px; // Remove cursor on webkit browsers
    }
  }
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: attr(value-formatted);
    line-height: @input-height;
  }
}
