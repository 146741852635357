@import "../../less/motomuto_ras/constants.less";

@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;


.MMToggle, .MMToggle > label {
  @height: 34px;
  @width: 62px;
  @toggle-size: 28px;
  @toggle-border: 8px;
  position: relative;
  display: inline-block;
  width: @width;
  height: @height;
  margin: 0 0 -13px 10px;

  input {
    display: none;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @greyred;
    transition: .4s;
    border-radius: 34px;
  }

  input:checked + span {
    background-color: @light-green2;
  }

  span:before {
    position: absolute;
    content: "";
    height: @toggle-size - @toggle-border*2;
    width: @toggle-size - @toggle-border*2;
    left: (@height - @toggle-size)/2;
    bottom: (@height - @toggle-size)/2;
    background-color: @dark-red;
    border: @red solid @toggle-border;
    transition: .4s;
    border-radius: 50%;
    z-index: 1;
  }

  input:checked + span:before {
    transform: translateX(@width - @height); // = width - border*2
    background-color: @dark-green2;
    border-color: @green;
  }

  input:disabled + span {
    opacity: 0.4;
    cursor: not-allowed;
  }
}
