@import "../../less/motomuto_ras/constants";

@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";

.MMSelect {
  position: relative;
  margin-top: 10px;
  @input-height: 30px;

  cursor: pointer;
  white-space: nowrap;

  .fontface-light();
  font-size: 16px !important;
  color: @dark-grey !important;

  &, .rc-select, .rc-select-selector {
    height: @input-height - 4px;
  }

  .rc-select-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  border: 2px solid @light-grey2;
  line-height: @input-height - 4px;

  & > .rc-select > .rc-select-selector > .rc-select-selection-search {
    display: none;
  }

  .rc-select-multiple {
    .rc-select-selection-item {
      background-color: @light-grey2;
      margin-right: 2px;
    }
  }

  .rc-select-selection-item {
    padding-left: 5px;
  }

  .anticon {
    position: absolute;
    right: 5px;
    top: 5px;
    pointer-events: none;
  }
}

.rc-select-dropdown {
  z-index: 1200;
  white-space: nowrap;

  .rc-select-item {
    padding: 0 5px;
    cursor: pointer;
    display: flex;
    &-option-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .fontface-light();
  font-size: 16px !important;
  color: @dark-grey !important;
  background-color: white;
  .menu-shadow();
}

.rc-select-dropdown-hidden {
  display: none;
}
