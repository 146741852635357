@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";
@import "../../less/motomuto_ras/constants";
@import "../../less/constants";

.MMModal {
  position: fixed;
  top: @menu-height /2;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  //height: calc(100% - @menu-height);

  &.large {
    .MMCard {
      &.modal {
        .vertical-transition(appear, -1000px, 10px);
      }
    }
  }

  .backdrop {
    background-color: @dark-grey;
    opacity: 0.7;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  & > .MMCard {
    z-index: 1001;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);

    &.modal {
      .vertical-transition(appear, -1000px, calc(50% - 640px / 2));
    }
  }

  .container {
    position: relative;
    height: 100%;
    width: 100%;

    .header {
      @header-height: 62px;
      @header-inner-height: @header-height - @default-padding * 2;
      height: @header-inner-height;
      padding-bottom: @default-padding; // Add padding to bottom, to compensate for MMCard padding
      border-bottom: 2px solid @light-grey2;
      width: 100%;
      display: flex;
      justify-content: space-between;

      .action-title {
        line-height: @header-inner-height;
        .fontface-bold();
        color: @dark-grey;
        font-size: 28px;
        margin: 0;
      }

      .loading-icon {
        display: none;
        margin-top: (@header-inner-height - 30px)/2;
        margin-left: 10px;

        svg {
          width: 30px;
          height: 30px;
        }
      }

      .align-left {
        display: inline-block;

        & > * {
          float: left;
        }
      }

      .align-right {
        display: inline-block;
        margin-right: 22px - @default-padding;

        & > * {
          float: left;
          @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
            //transform: scale(0.9);
            margin-top: -13px !important;
            margin-right: 25px !important;
          }
        }

        .scenario-title {
          .fontface-light();
          font-size: 24px;
          color: @dark-grey;
          margin: 0 34px 0 0;
          line-height: @header-inner-height;
        }

        .icon-edit {
          @icon-height: 17px;
          margin-top: (@header-inner-height - @icon-height)/2;
          //height: 100%;

          & > div {
            height: @icon-height;
          }

          .icon-edit-svg {
            height: @icon-height;
            width: unset;

            g * {
              fill: @light-grey !important;
            }
          }
        }
      }
    }

    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: white;

      .align-right {
        position: absolute;
        right: 0;
        bottom: 0;
        display: inline-block;

        & > * {
          float: left;
          margin-left: 19px;
        }
      }
    }

    .body {
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(100% - 120px);
      height: inherit;

      & p {
        .fontface-extra-light();
        color: @dark-grey;
        margin: 0;
      }

      .submit {
        position: absolute;
        bottom: 0;
        width: 288px;
        right: 0;
      }
    }
  }

  &.loading {
    .loading-icon {
      display: inline-block !important;
    }
  }
}
