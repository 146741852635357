@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../../less/motomuto_ras/fonts";
@import "../../../less/motomuto_ras/constants";

.ScenesHeader {
  @submenu-height: 42px;
  @icon-height: 17px;
  width: 100%;
  height: @submenu-height;
  display: flex;
  position: relative;
  background-color: @light-grey3;

  .buttons {
    width: 100%;
    display: inline-flex;
    justify-content: space-evenly;
  }

  .notification-shadow();

  .ant-divider {
    width: 1px;
    background-color: @mm-dark-grey;
  }

  .MMSelect {
    flex-shrink: 0;
    margin: 2px 20px;
    width: 300px;
    line-height: 35px;

    &, & > *, & > * > * {
      height: 35px;
    }
    .anticon {
      top: 0;
    }

    background-color: @mm-white;
  }

  .menu-item {
    cursor: pointer;
    border: 0;
    background-color: transparent;

    &:hover {
      background-color: @light-grey;
    }

    &:disabled {
      opacity: 0.6;
      background-color: transparent;
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
    }

    display: inline-flex;
    @right-icon-height: 22.5px;
    line-height: @submenu-height - 1px;
    height: @submenu-height;

    .icon {
      height: @right-icon-height;

      & > div {
        display: flex !important;
      }
    }

    p {
      .fontface-semibold();
      font-size: 16px;
      color: @dark-grey;
      margin: 0 0 0 14px;
    }

    .injected-svg {
      margin-top: (@submenu-height - @right-icon-height)/2;
      height: @right-icon-height;
      width: unset;

      * {
        fill: @dark-grey !important;
      }

      &.icon-remove-svg > g > g {
        path {
          stroke: @dark-grey !important;
        }

        path:nth-child(-n+2) {
          fill: none !important;
        }
      }
    }

    &.active {
      background-color: @dark-grey;

      p {
        color: @light-grey3;
      }

      .injected-svg {
        * {
          fill: @light-grey3 !important;
          stroke: @light-grey3 !important;
        }

        &.icon-remove-svg > g > g {
          path {
            stroke: @light-grey3 !important;
          }

          path:nth-child(-n+2) {
            fill: none !important;
          }
        }
      }
    }
  }

  .title {
    .fontface-light();
    font-size: 16px;
    color: @dark-grey;
    line-height: 42px;
    height: 42px;
    width: 60px;
    margin: 0;
    text-align: center;
  }

  .ant-menu {
    width: 300px;
    line-height: @submenu-height;
    background-color: @light-grey3;

    .ant-menu-item {
      .fontface-semibold();
      height: @submenu-height;
      padding: 0 25px;
      display: inline-flex;
    }

    .icon-menu {
      margin: 0 12.3px 0 0;
      height: @icon-height;
      line-height: 47px;

      & > div {
        height: @icon-height;
      }

      .icon-menu-svg {
        height: @icon-height;
        width: unset;

        * {
          fill: @light-grey !important;
        }
      }
    }

    & .ant-menu-item-selected {
      .icon-menu-svg {
        * {
          fill: @dark-grey !important;
        }
      }
    }
  }

  p {
    .fontface-bold();
    font-size: 28px;
    color: @light-grey2;
    margin: -10px 35px;
  }
}
