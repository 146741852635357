@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../../less/motomuto_ras/fonts";

.NamingComponent {
  @height: 32px;
  @svg-width: 17.5px;
  display: inline-flex;
  height: @height;
  padding-bottom: 12px;
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
    padding-bottom: 12px * 0.8;
  }

  .name-input {
    input {
      .fontface-light();
      font-size: 16px;
      color: @dark-grey;
    }
    .icon-edit {
      position: absolute;
      right: 0.8px;
      top: 3.8px;
      & > div {
        display: flex;
      }
      .icon-edit-svg {
        height: 17px;
        width: unset;
        fill: @dark-grey;
      }
    }
  }

  .address {
    .fontface-semibold();
    color: @dark-grey;
    margin: 0 8px 0 0;
    line-height: 32px;
    width: 26px;
    height: @height;
    text-align: end;
    min-width: 26px;
  }

  .icon {
    transform: translateY(-50%);
    margin: @height/2 4px 0 0;
    width: @svg-width;
    display: flex;

    & > div {
      display: flex;
    }

    .icon-svg {
      height: unset;
      width: @svg-width;

      g * {
        fill: @light-grey !important;
      }
    }
  }
}
