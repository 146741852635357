@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";

.LightPage {
  margin: auto;
  max-width: 1024px - 50px * 2;
  height: 100%;

  .locations {
    margin-bottom: -27px;
  }

  .fullscreen-info {
    position: fixed;
    flex-flow: wrap;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .login-brand-logo-container {
      margin: auto;
      width: 280px;
      position: relative;

      .login-brand-logo, .login-brand-logo-svg {
        width: 100%;
        position: absolute;
        bottom: 20px;
      }
    }

    & > p {
      text-align: center;
      font-size: 40px;
      .fontface-semibold();
      color: @dark-grey;
    }
  }

  .category {
    .header, .header-empty {
      width: 100%;
      display: inline-block;
      position: relative;
      margin-bottom: 18px;
      height: 30px;
      @media (max-width: 659px) {
        height: 0;
        margin-bottom: 0;
      }
    }

    .header {
      border-bottom: 1px solid @white;

      .injected-svg {
        position: absolute;
        top: 0;
        height: 25px;
        width: unset;

        g * {
          fill: @light-grey2 !important;
        }
      }

      p {
        .fontface-bold();
        font-size: 28px;
        color: @light-grey2;
        margin: -10px 35px;
      }
    }

    .CuelistElement {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 15px;
      }

      &.override .override * {
        fill: @red !important;
      }
    }

    .ant-row {
      min-width: 825px;

      @media (max-width: 824px) {
        min-width: 100vw;
      }

      & > .ant-col {
        padding-bottom: 27px;
        max-width: unset;
        @media (max-width: 659px) {
          flex: 0 0 33.3%;
        }
        @media (max-width: 494px) {
          flex: 0 0 50%;
        }
        @media (max-width: 329px) {
          flex: 0 0 100%;
        }
      }
    }

    &.indoor {
      .CuelistElement.off {
        .scenario-icon-svg g * {
          &:nth-child(-n+4) {
            fill: transparent !important;
          }
        }
      }
    }

    &.outdoor {
      .CuelistElement.off {
        .scenario-icon-svg g * {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(10) {
            fill: transparent !important;
          }
        }
      }
    }
  }
}
