.node-red {
  &, & > iframe {
    position: fixed;
    top: 61px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% - 61px);
  }
}
