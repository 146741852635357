@import-js "constants";

// Deprecated
@mm-green: rgb(140, 198, 63);
@mm-orange: rgb(255, 80, 0);
@mm-background: rgb(244, 236, 235);
@mm-red: rgb(169, 68, 66);
@mm-dark-grey: rgb(51, 62, 72);
@mm-light-grey: rgb(224, 224, 224);
@mm-white: rgb(255, 255, 255);
@background: #FFFFFF;
@background-fallback: center; // Fallback is unused for backgrounds without gradients

// Shadows
.default-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid @dark-grey2;
}

.notification-shadow {
  //box-shadow: rgba(0, 0, 0, 0.16) 0 6px 6px;
}

.menu-shadow {
  //box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
}

// Modal & login styling
.ant-notification-modal-style {
  .ant-notification {
    top: 0 !important;
    z-index: 1300;
  }
}

// Timetable timeline
@timetable-timeline-day-height: 66px;
@timetable-timeline-day-schedule-height: 38px;
@timetable-timeline-text-height: 22px;
