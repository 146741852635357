// ANT Design
@import "~antd/es/grid/style/index";
@import "~antd/es/menu/style/index";
@import "~antd/es/notification/style/index";
@import "~antd/es/slider/style/index";
@import "~antd/es/style/core/iconfont";
@import "~antd/es/style/core/motion/other";
@import "~antd/es/tooltip/style/index";
@import "~antd/es/upload/style/index";
@import "~antd/es/table/style/index";
@import "~antd/es/pagination/style/index";
@import "~antd/es/time-picker/style/index";
@import "~antd/es/date-picker/style/index";

.ant-picker-dropdown {
  @import "~antd/es/style/core/index";
}

// Constants
@import "less/motomuto_ras/styling";
@import "less/motomuto_ras/constants";

@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "less/motomuto_ras/fonts";

html {
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.anticon:focus {
  outline: none;
}

@spinnerSize: 7vw;
.spinContainer {
  height: @spinnerSize;
  width: @spinnerSize;
  margin: auto;
}

.spinContainer span {
  font-size: @spinnerSize;
}

.loading-container {
  z-index: 5000;
  background-color: @white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & > * {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .loading-svg {
    width: 50vw;
    height: 15vh;
  }
}

// Notifications
@keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    top: -52px;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.ico,
.ico > div {
  display: flex;
  width: fit-content;
}

.ant-notification {
  width: 100%;
  max-width: 100%;
  top: 61px !important;
  margin: 0;


  .ant-notification-notice {
    border-radius: 0;
    .notification-shadow();
    padding: 6px 24px;
    background-color: @yellow;
    width: 100%;

    &-fade-enter&-fade-enter-active,
    &-fade-appear&-fade-appear-active {
      animation-name: NotificationFadeIn;
      animation-play-state: running;
    }

    .ant-notification-notice-close {
      //display: none;
      transform: scale(2);
      line-height: 35px;
    }

    .ant-notification-notice-content {
      height: 52px;
      display: flex;

      .ant-notification-notice-with-icon {
        margin: 0 auto;

        .ant-notification-notice-icon {
          top: 11px;
          bottom: 11px;
          margin: 0;
        }

        .ant-notification-notice-message {
          margin-top: 0;
          .fontface-semibold();
          color: @dark-grey;
          max-width: 400px;
          height: 42px;
          display: grid;
        }
      }

      .ant-notification-notice-description {
        display: none;
      }
    }
  }
}

// SVG
.ico-info > path {
  fill: @dark-grey;
}

iframe {
  border: none;
}

.content-container {
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 61px;
  left: 0;
  right: 0;
  bottom: 75px;

  display: grid;

  & > div {
    margin: auto;
  }

  &.logged-in > div {
    margin: 0 auto;
  }
}

.disable-menu {
  .content-container {
    top: 0;
  }
}

// Menu
.menu-container {
  width: 100%;
  background-color: @white;
  height: @menu-height;
  position: fixed;
  z-index: 1;
  display: inline-flex;

  .align-left,
  .align-center,
  .align-right {
    position: absolute;
    height: 100%;
    display: flex;
  }

  .align-left {
    left: 0;
  }

  .align-center {
    left: 50%;
    transform: translateX(-50%);
  }

  .align-right {
    right: 0;
  }

  .menu-shadow();

  .signout-container {
    cursor: pointer;
    height: 32px;
    display: inline-block;
    align-self: center;
    margin-left: 20px;

    .fontface-semibold();
    font-size: 16px;
    line-height: 32px;
    color: @light-grey;

    .injected-svg {
      height: 32px;
      width: unset;

      g * {
        fill: @light-grey !important;
      }
    }

    & > * {
      float: left;
    }

    p {
      margin: 0 15px;
    }
  }

  .info-container {
    margin-right: 20px;
    align-self: center;
  }

  .current-time, .device-name, .project-name {
    margin: 0;
    text-align: right;

    .fontface-semibold();
    font-size: 16px;
    line-height: 16px;
    color: @light-grey;
  }

  .device-name, .project-name {
    color: @dark-grey;
  }

  .project-name {
    cursor: pointer;
  }
}

.ant-menu {
  line-height: @menu-height;
  max-width: 725px;
  border: none;
  transition: background 0.3s, width 0.2s;
  box-shadow: none;
  display: flex;
  justify-content: space-between;

  .ant-menu-submenu {
    display: none;
  }

  .ant-menu-item {
    cursor: pointer;
    display: inline-block;
    vertical-align: bottom;
    flex: 80px;
    text-align: center;
    height: @menu-height;
    .fontface-regular();
    color: @light-grey;
    font-size: 16px;
    padding: 0;

    background-size: 100px 6px, calc(100% - 6px) 100%;
    background-position: center bottom, 6px 0;
    border-bottom: none !important;
    transition: none;

    &.ant-menu-item-selected,
    &.ant-menu-item-active {
      background-image: linear-gradient(@orange, @orange) !important;
      background-repeat: no-repeat;

      .fontface-semibold();
      color: @dark-grey !important;
      border-bottom: unset !important;
    }

    &:active {
      // background: rgba(0, 0, 0, 0.05);
    }
  }
}

body {
  margin: 0;
  background: @background @background-fallback;
  user-select: none;

  &.login, &.modal, &.disable-menu {
    .ant-notification-modal-style();
  }
}

.initial-setup {
  margin: 80px 50%;
  display: inline-block;
  transform: translateX(-50%);
}

.Watermarks {
  .motomuto {
    & > g > g > * {
      fill: #3b444d !important;
    }
  }

  .motomuto-dark-grey {
    & > g > g > * {
      fill: @dark-grey !important;
    }
  }

  .synslab {
    width: 250px;
    height: 48px;
  }
}

.project-elements {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  @media (max-width: 369px) {
    justify-content: space-evenly;
  }

  .project-element {
    margin-bottom: 10px;
  }

  .ico-container svg {
    width: 80px !important;
    height: 80px !important;
    padding: calc((100% - 80px) / 2) 0 !important;
  }
}

.ant-slider {
  margin-top: 20px;

  .ant-slider-track,
  &:hover .ant-slider-track {
    background-color: @orange;
  }

  .ant-slider-rail,
  &:hover .ant-slider-rail {
    background-color: @light-grey5;
  }

  .ant-slider-rail,
  .ant-slider-track {
    height: 6px;
    outline: none;
  }

  .ant-slider-dot {
    height: 15px;
    width: 15px;
    border: none;
    background-color: @light-grey5;
    top: -5px;

    &-active {
      background-color: @orange;
    }
  }

  .ant-slider-handle {
    height: 25px;
    width: 25px;
    border: none;
    background-color: @orange;
    top: 0;
    box-shadow: none;
  }

  .ant-slider-mark-text {
    top: -40px;
    .fontface-light();
    font-size: 16px;
    color: @dark-grey3;

    &-selected {
      .fontface-semibold();
    }
  }

  &.red {
    .ant-slider-track,
    &:hover .ant-slider-track,
    .ant-slider-handle,
    .ant-slider-dot-active {
      background-color: @red;
    }
  }

  &.green {
    .ant-slider-track,
    &:hover .ant-slider-track,
    .ant-slider-handle,
    .ant-slider-dot-active {
      background-color: @green;
    }
  }

  &.blue {
    .ant-slider-track,
    &:hover .ant-slider-track,
    .ant-slider-handle,
    .ant-slider-dot-active {
      background-color: @blue;
    }
  }

  &.cold-white {
    .ant-slider-track,
    &:hover .ant-slider-track,
    .ant-slider-handle,
    .ant-slider-dot-active {
      background-color: @cold-white;
    }
  }

  &.warm-white {
    .ant-slider-track,
    &:hover .ant-slider-track,
    .ant-slider-handle,
    .ant-slider-dot-active {
      background-color: @warm-white;
    }
  }
}

.MMModal.login {
  .container {
    .header, .footer {
      display: none;
    }
  }
}

.popup-menu {
  position: absolute;
  z-index: 1;
  transform: scale(calc(1 / var(--scale))) translate(calc(5px + 1px * var(--scale)), calc(5px + 1px * var(--scale)));
  transform-origin: 0 0;
}

.ant-table-wrapper {
  .ant-table-thead {
    .fontface-semibold();
    color: @dark-grey;
  }

  .ant-table-cell {
    padding: 5px;
    .fontface-light();
  }

  .MMSelect {
    display: inline-block;
    margin: 0;
    width: 200px;
  }

  .actions {
    display: inline-flex;
    justify-content: space-between;

    & > * {
      cursor: pointer;
      margin-right: 10px;
    }

    .anticon {
      margin-top: 2px;
      font-size: 28px;
    }

    .icon {
      @icon-height: 30px;

      & > div {
        height: @icon-height;
      }

      .injected-svg {
        height: @icon-height;
        width: unset;
      }
    }
  }

  .shift-cell {
    display: inline-flex;
    justify-content: space-between;
    width: 200px;

    .MMTextInput {
      width: 40px;
    }
  }

  .MMTextInput {
    margin: 0;
    width: 70px;

    input {
      font-size: 16px;
    }
  }
}

.text-postfix {
  font-size: 16px !important;
  display: inline-flex;
  .fontface-light();
  line-height: normal;
  color: @dark-grey;

  & > * {
    margin: 0;
    border-bottom: 4px solid @light-grey2 !important;

    &.MMTextInput {
      margin-left: 5px;
      width: 50px;
    }

    input {
      // font-size: 16px !important;
      border: none !important;
      text-align: right !important;
      width: 100%;
    }
  }

  p {
    font-size: 16px !important;
    margin: unset !important;
  }
}
