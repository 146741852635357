@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";
@import "../../less/constants";

.ScreensaverPage {
  .time {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-768px / 2 + 115px);
    .fontface-semibold();
    font-size: 80px;
    color: @white;
    margin: 0;
    text-align: center;
  }

  .date {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-768px / 2 + 225px);
    .fontface-semibold();
    font-size: 20px;
    color: @light-grey;

    margin: 0;
    text-align: center;
  }

  .device-name {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-768px / 2 + 45px);
    .fontface-semibold();
    font-size: 40px;
    color: @white;

    margin: 0;
    text-align: center;
  }

  .content {
    position: fixed;
    top: -1000px;
    left: 0;
    right: 0;
    height: 100%;
    background-color: @dark-grey;
    background-image: url("../../assets/screensaver.jpg");
    background-position: center;
    background-size: cover;
    z-index: 2000;

    &.screensaver {
      .vertical-transition(enter, -1000px, 0);
    }
  }

  .current-scenarios {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 128px;
    display: inline-flex;
    justify-content: space-between;

    .cuelist-container {
      padding-right: 178px/2;
      margin-right: 178px/2;
      border-right: 1px solid @light-grey2;
      height: 230px;

      &:nth-last-child(1) {
        padding-right: 0;
        margin-right: 0;
        border-right: none;
      }

      .location-title {
        .fontface-semibold();
        color: @light-grey2;
        font-size: 20px;
        text-align: center;
        margin: 0 0 27px;
      }

      .CuelistElement {
        height: 128px;
        width: 128px;

        &.override {
          .id-container {
            color: @red;
          }
        }

        .ico-container {
          margin: 0;
          width: 128px - 5px *2;
          height: 128px - 5px *2;
        }

        .id-container {
          position: absolute;
          bottom: -18px;
          background-color: transparent;
          .fontface-semibold();
          font-size: 20px;
          color: @yellow;
        }
      }
    }
  }

  .message {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(768px / 2 - 145px);

    .fontface-semibold();
    font-size: 20px;
    color: @light-grey;

    margin: 0;
    text-align: center;
  }
}
