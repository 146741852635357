@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";

.MMPinInput {
  @margin: 12px;
  display: flex;

  input {
    -webkit-text-security: disc;
    background: transparent;
    margin-left: @margin;
    margin-right: @margin;
    width: 52px !important;

    &:nth-child(1) {
      margin-left: 0 !important;
    }

    &:nth-last-child(1) {
      margin-right: 0 !important;
    }

    border: none !important;
    border-bottom: 4px solid @light-grey2 !important;
    border-radius: 0;
    height: 48px;
    font-size: 24px;
    color: @dark-grey;
    text-align: center;

    // Hide arrows
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  input:focus {
    outline: 0;
    color: black;
  }

  &.block {
    width: 100%;
    display: inline-flex;

    input {
      width: calc((100% - @margin * 6) / 4) !important;
      @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
        // Shitty webkit
        margin-left: @margin *0.5;
        margin-right: @margin *0.5;
      }
    }
  }
}
