@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";

.MMIPInput {
  justify-content: space-between;
  width: 100%;
  display: inline-flex;

  .input-subgroup {
    width: 23%;
    max-width: 55px;
    position: relative;
  }

  input {
    background: transparent;
    border: none !important;
    border-bottom: 4px solid @light-grey2 !important;
    border-radius: 0;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    //noinspection CssInvalidPropertyValue
    width: -webkit-fill-available;
    .fontface-light();
    font-size: 24px;
    color: @dark-grey;
    text-align: center;

    // Hide arrows
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  input:focus {
    outline: 0;
    color: black;
  }
}
