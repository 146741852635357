@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/constants";
@import "../../less/motomuto_ras/fonts";

.MMTextInput {
  @margin-sides: 0;
  position: relative;
  margin-bottom: 15px;
  cursor: text;

  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
  }

  input {
    background: transparent;
    box-shadow: none;
    margin-left: @margin-sides !important;
    margin-right: @margin-sides !important;
    height: 100%;
    width: 100%;

    border: none;
    border-bottom: 4px solid @light-grey2;
    border-radius: 0;
    padding: 0;
    font-size: 24px;
    max-width: 100%;

    .fontface-light();
    color: @dark-grey;
    text-overflow: ellipsis;
  }

  input:focus {
    outline: 0;
    color: black;
  }
  input::placeholder {
    color: @light-grey;
  }

  input:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.error * {
    color: @mm-red;
    border-color: @mm-red;
  }
  &.block input {
    width: 100%;
  }
}
