@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../../less/motomuto_ras/fonts";
@import "../../../less/motomuto_ras/constants";

.CuelistElement {
  cursor: pointer;
  width: 165px;
  height: 165px;
  position: relative;
  margin: auto;

  @ico-container-size: 116px;

  &.gone {
    cursor: default;
  }

  .action-container {
    @size: 42.5px;
    @border: 8px;
    position: absolute;
    z-index: 0;
    height: @size;
    top: -@size/3;
    left: (165px - @ico-container-size)/2 + @ico-container-size - @size/3*1 - @border/2;
    width: max-content;
    padding-right: 4.25px;
    background-color: @white;
    border-radius: @size;
    padding-left: @size;
    z-index: 2;

    &.closed {
      background-color: transparent;

      .action-element {
        display: none;
      }
    }

    &.single {
      .action-button {
        display: none;
      }

      .action-element {
        display: block;
      }
    }

    & > * {
      display: inline-block;
    }

    .action-element {
      @size: 34px;
      @border: 2px;
      @icon-size: 17px;
      margin-top: 4.25px;
      margin-left: 2px;
      height: @size - @border *2;
      width: @size - @border *2;
      background-color: @white;
      border: @dark-grey solid @border;
      border-radius: 50%;

      svg {
        * {
          fill: @dark-grey !important;
        }

        height: @icon-size;
        width: @icon-size;
        margin: auto;
        padding: (@size - @border *2 - @icon-size)/2;
      }
    }

    .action-button, &.single > .action-element {
      position: absolute;
      left: 0;
      @icon-size: 17px;
      height: @size - @border *2;
      width: @size - @border *2;
      background-color: @white;
      border: @light-grey2 solid @border;
      border-radius: 50%;

      svg {
        * {
          fill: @dark-grey !important;
        }

        height: @icon-size;
        width: @icon-size;
        margin: auto;
        padding: (@size - @border *2 - @icon-size)/2;
      }
    }

    .action-button * {
      pointer-events: none;
    }
  }

  @phybtn-margin: 6px;
  @icon-size: 70px;

  .ico-container {
    width: @ico-container-size;
    height: @ico-container-size;
    background-color: @light-grey2;
    margin: 0 auto;
    position: relative;
    z-index: -2;

    .scenario-icon-svg {
      display: block;
      padding: calc((100% - @icon-size) / 2) 0;
      margin: 0 auto;
      height: @icon-size;
      width: unset;

      g *,
      polygon {
        fill: @light-grey !important;
      }
    }

    .color-container {
      position: absolute;
      bottom: @phybtn-margin;
      left: @phybtn-margin;
      border-width: 22px 0 0 22px;
      border-style: solid;
      border-color: transparent;
    }

    .phybtn-container, .priority-container {
      position: absolute;
      top: @phybtn-margin;
      left: @phybtn-margin;
      background-color: @light-grey;
      height: 22px;
      width: 22px;
      border-radius: 2px;

      p {
        text-align: center;
        margin: 0;
        .fontface-bold();
        font-size: 18px;
        color: @light-grey2;
        padding: 0;
        line-height: 22px;
      }
    }

    .priority-container {
      top: unset;
      left: unset;
      right: @phybtn-margin;
      bottom: @phybtn-margin;
      p {
        font-size: 12px;
      }
    }
  }

  .id-container {
    margin-bottom: 0;
    width: 100%;
    height: 28px;
    background-color: @light-grey2;
    border-radius: 4px;
    text-align: center;
    line-height: 28px;


    .fontface-semibold();
    font-size: 16px;
    color: @light-grey;
  }

  @active-border: 5px;

  &.on, &.override, &.partly_on {
    .ico-container {
      .default-shadow();
      background-color: @white;
      border: @yellow solid @active-border;

      width: @ico-container-size - 2* @active-border;
      height: @ico-container-size - 2* @active-border;

      .scenario-icon-svg g *,
      .scenario-icon-svg polygon {
        fill: @dark-grey !important;
      }

      .phybtn-container, .priority-container {
        background-color: @dark-grey;
        top: @phybtn-margin - @active-border;
        left: @phybtn-margin - @active-border;

        p {
          color: @light-grey2;
        }
      }

      .priority-container {
        top: unset;
        left: unset;
        right: @phybtn-margin - @active-border;
        bottom: @phybtn-margin - @active-border;
      }

      .color-container {
        bottom: @phybtn-margin - @active-border;
        left: @phybtn-margin - @active-border;
      }
    }

    .id-container {
      background-color: @yellow;
      color: @dark-grey;
    }
  }

  &.partly_on {
    .ico-container {
      border: @yellow2 solid @active-border;
    }

    .id-container {
      background-color: @yellow2;
    }
  }

  &.override {
    .ico-container {
      border: @red solid @active-border;
    }

    .id-container {
      background-color: @red;
      color: @white;
    }
  }

  &.off {
    .ico-container {
      background-color: @dark-grey;

      .scenario-icon-svg g *,
      .scenario-icon-svg polygon {
        fill: @white !important;
      }

      .phybtn-container, .priority-container {
        background-color: @light-grey2;

        p {
          color: @dark-grey;
        }
      }
    }

    .id-container {
      color: @dark-grey;
    }
  }
}
