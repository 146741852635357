@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../../less/motomuto_ras/fonts";

.UniversesPage {
  .header {
    margin-bottom: 5px !important;
  }

  .slave-warning {
    margin: auto;
    .fontface-light();
    color: @light-grey;
    font-size: 16px;
  }

  .buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
     & > * {
       margin-left: 5px;
       float: right;
       padding-left: 20px;
       padding-right: 20px;
     }
  }

  .input-group {
    & > p {
      color: @light-grey;
      margin: 0;
    }

    p {
      .fontface-semibold();
      font-size: 16px;
    }

    .MMTextInput, .MMIPInput, .MMDatePicker, .MMTimePicker {
      height: 32px;

      span {
        line-height: 32px !important;
      }
    }

    input, .MMDatePicker span, .MMTimePicker span {
      .fontface-light();
      font-size: 16px;
      color: @dark-grey;
    }

    .inline {
      display: inline-block;
      width: 100%;

      > :nth-child(1) {
        float: left;
      }

      > :nth-child(2) {
        float: right;
      }
    }
  }
}
