@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../../less/motomuto_ras/fonts";
@import "../../../less/motomuto_ras/constants";
@import "../../../less/constants";

.ScenarioEditor {
  .ant-row {
    width: 850px;
    margin-top: 10px;
  }

  .debug-info {
    font-size: 12px;
    color: @dark-grey;
    line-height: 0;
    margin-top: 8px;
    .fontface-extra-light();
  }

  .fixture-scene-component {
    margin-bottom: 30px;
    display: inline-flex;
    justify-content: flex-end;
    width: 420px;
    height: 33px;

    &.relay,
    &.daylight-control {
      justify-content: unset;
    }

    .debug-info {
      position: absolute;
      margin-top: 40px;
    }

    &:nth-child(2n) {
      float: right;
    }

    .fixture-name {
      width: 160px;
      margin: 0;
      .fontface-regular();
      font-size: 16px;
      color: @light-grey;
      line-height: 33px;
      text-align: center;
      overflow-wrap: break-word;
    }

    .MMToggle, .MMSlider {
      margin-top: 33px/2;
      transform: translateY(-50%);
    }
  }
}
