@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";
@import "../../less/motomuto_ras/constants";

.MMLoginBox {
  position: relative;
  height: 100%;
  width: 100%;

  .login-title {
    color: @dark-grey;
    .fontface-bold();
    font-size: 28px;
    margin: -7.5px 0 0;
  }

  .login-subtitle, .login-subsubtitle {
    margin: 0;
    color: @dark-grey;
    .fontface-semibold();
    font-size: 20px;
  }

  .login-subsubtitle {
    .fontface-light();
    font-size: 16px;
  }

  .login-submit {
    position: absolute;
    bottom: 0;
  }

  .login-pincode {
    position: absolute;
    bottom: 131px - 15px;
    width: 100%;
    border: none;
    margin: auto;


    div {
      input {
        margin: 0 !important;
        margin-left: 12px !important;
        margin-right: 12px !important;

        &:nth-child(1) {
          margin-left: 0 !important;
        }

        &:nth-last-child(1) {
          margin-right: 0 !important;
        }

        border: none !important;
        border-bottom: 4px solid @light-grey2 !important;
        border-radius: 0;
        font-size: 80px;
        color: @dark-grey;
        padding: 1px !important;

        @media all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape) {
          line-height: 10px;
          height: 60px !important;
          font-size: 60px;
        }
      }

      input:focus {
        outline: 0;
      }
    }
  }
}
