@menu-height: 61px;
@orange: #FF9900;
@black: #272727;
@dark-grey: #575757;
@dark-grey2: #707070;
@grey: #919191;
@red: #FF4600;
@dark-red: #973D1B;
@greyblue: #B3C3DC;
@yellow: #FFE787;
@yellow2: #D6C068;
@yellow3: #FFCC00;
@yellow4: #FFECA2;
@dark-green: #70955B;
@dark-green2: #5E804B;
@green: #88B86D;
@light-green: #94E373;
@light-green2: #C4F1AB;
@light-green3: #7ED680;
@white: #FFFFFF;
@greyred: #e9b6a3;
@light-grey: #C4C4C4;
@light-grey2: #ECECEC;
@light-grey3: #E3E3E3;
@light-grey4: #B8B8B8;
@default-padding: 15px;
@fader-height: 18px;
@fader-thumb-border: 10px;
@fader-thumb-size: 20px;
@modal-transition-duration: 300ms;
@light-grey5: #EFEFEF;
@blue: #58A3D5;
@warm-white: #FFDC51;
@cold-white: #AEDFFF;
@dark-grey3: #404040;

@import "../../less/motomuto_ras/fonts";

.SettingsPage {
  display: flex;

  .WatermarkContainer.right.bottom {
    display: none;
  }

  .watermark-version {
    .fontface-light();
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 5px;
    margin: 0;
    color: @dark-grey2;
  }

  .container {
    position: relative;
    height: 100%;
    width: 100%;

    .header {
      color: @dark-grey;
      .fontface-bold();
      font-size: 28px;
      margin: 0 0 25px;
    }

    .body {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 500px;
      height: inherit;

      .submit {
        position: absolute;
        bottom: 0;
      }
    }

    &.naming {
      .header {
        margin-bottom: 0;
      }

      .body {
        .location-header {
          width: 100%;
          height: unset;
          display: inline-block;
          position: relative;
          margin-top: 30px;
          margin-bottom: 5px;

          .injected-svg {
            position: absolute;
            bottom: 0;
            height: 25px;
            width: unset;

            * {
              fill: @dark-grey !important;
            }
          }

          p {
            .fontface-semibold();
            font-size: 16px;
            color: @dark-grey;
            margin: -22px 35px;
          }
        }

        .submit {
          width: 288px;
          right: 0;
        }

        .dali-label {
          .fontface-regular();
          font-size: 10px;
          color: @light-grey;
          margin: 0 0 0 28.5px;

          span {
            margin: 0 0 0 270px;
          }
        }

        .ant-row {
          justify-content: space-between;
        }

        .NamingComponent {
          width: 49%;

          &:nth-child(2n) {
            float: right;
          }
        }

        .align-bottom {
          width: 100%;

          .NamingComponent {
            &:nth-child(2n+1) {
              float: left;
            }

            &:nth-child(2n) {
              float: right;
            }
          }
        }
      }
    }
  }

  @media (max-height: 850px) {
    .Watermark {
      display: none;
    }
  }
  @media (max-height: 750px) {
    .watermark-version {
      display: none;
    }
  }
}
