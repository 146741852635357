@import "../../less/motomuto_ras/fonts";

.Watermarks {
  .WatermarkContainer {
    position: fixed;
    z-index: 20;
    left: 16px;
    bottom: 16px;
    display: inline-flex;
    .Watermark {
      width: 96px;
    }
    .WatermarkTextBefore {
      .fontface-extra-light();
      font-size: 20px;
      line-height: 23px;
      margin: 0 10px 0 0;
    }
  }
}
